var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"pt-3 appic-table-light specification-table",attrs:{"headers":_vm.contactHeaders,"items":_vm.IntegratedContacts,"search":_vm.contactSearchTerm,"calculate-widths":"","dense":"","item-key":"Invoice.id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex flex-row align-center"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('message.buyers')))])]),_c('div',{staticClass:"d-flex flex-row mb-3"},[_c('v-text-field',{staticClass:"filter-width-250",attrs:{"value":_vm.contactSearchTerm,"append-icon":"search","dense":"","hide-details":"auto","solo":""},on:{"change":function($event){_vm.contactSearchTerm = $event}}}),_c('v-btn',{staticClass:"ml-3",on:{"click":_vm.resetContactSearch}},[_vm._v(_vm._s(_vm.$t('message.clear')))])],1)]},proxy:true},{key:"item.Contact.Processed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Contact.Processed != '0000-00-00' ? _vm.formatDate(item.Contact.Processed) : '')+" ")]}},{key:"item.Contact.Addresses",fn:function(ref){
var item = ref.item;
return [_vm._l((item.Contact.Addresses),function(address,index){return [_c('div',{key:index,staticClass:"d-flex flex-row"},[_c('span',[_vm._v(_vm._s(address.AddressType + ': ' + (address.AddressLine1 != null ? address.AddressLine1.trim() : '') + (address.AddressLine2 != null ? address.AddressLine2.trim() : '') + (address.AddressLine3 != null ? address.AddressLine3.trim() : '') + (address.City != null ? address.City.trim() : '') + (address.Region != null ? address.Region.trim() : '') + (address.PostalCode != null ? address.PostalCode.trim() : '') + (address.Country != '' ? address.Country.trim() : '')))])])]})]}},{key:"item.Contact.ContactPersons",fn:function(ref){
var item = ref.item;
return [_vm._l((item.Contact.ContactPersons),function(contactPerson,index){return [_c('div',{key:index,staticClass:"d-flex flex-row"},[_c('span',[_vm._v(_vm._s(contactPerson.FirstName != null ? contactPerson.FirstName.trim() : ''))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s('(' + (contactPerson.EmailAddress != null ? contactPerson.EmailAddress.trim() : '') + ')'))])])]})]}},{key:"item.Contact.Phones",fn:function(ref){
var item = ref.item;
return [_vm._l((item.Contact.Phones),function(phone,index){return [_c('div',{key:index,staticClass:"d-flex flex-row"},[_c('span',[_vm._v(_vm._s(phone.PhoneType + ': ' + phone.PhoneNumber))])])]})]}}])}),_c('v-data-table',{staticClass:"appic-table-light specification-table",attrs:{"headers":_vm.invoiceHeaders,"items":_vm.IntegratedInvoices,"search":_vm.invoiceSearchTerm,"calculate-widths":"","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex flex-row align-center"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('message.invoices')))])]),_c('div',{staticClass:"d-flex flex-row mb-3"},[_c('v-text-field',{staticClass:"filter-width-250",attrs:{"value":_vm.invoiceSearchTerm,"append-icon":"search","dense":"","hide-details":"auto","solo":""},on:{"change":function($event){_vm.invoiceSearchTerm = $event}}}),_c('v-btn',{staticClass:"ml-3",on:{"click":_vm.resetInvoiceSearch}},[_vm._v(_vm._s(_vm.$t('message.clear')))])],1)]},proxy:true},{key:"item.Invoice.Processed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Invoice.Processed != '0000-00-00' ? _vm.formatDate(item.Invoice.Processed) : '')+" ")]}},{key:"item.Invoice.Date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Invoice.Date != '0000-00-00' ? _vm.formatDate(item.Invoice.Date) : '')+" ")]}},{key:"item.Invoice.DueDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Invoice.DueDate != '0000-00-00' ? _vm.formatDate(item.Invoice.DueDate) : '')+" ")]}},{key:"item.Invoice.LineItems",fn:function(ref){
var item = ref.item;
return [_c('v-simple-table',{staticClass:"appic-table-light simple-table my-2",staticStyle:{"width":"400px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"pl-1",staticStyle:{"background-color":"unset !important","height":"20px !important"}},[_vm._v("ItemCode")]),_c('th',{staticClass:"pl-1",staticStyle:{"height":"20px !important"}},[_vm._v("Description")]),_c('th',{staticClass:"pl-1",staticStyle:{"height":"20px !important"}},[_vm._v("Quantity")]),_c('th',{staticClass:"pl-1",staticStyle:{"height":"20px !important"}},[_vm._v("UnitAmount")])])]),_c('tbody',[_vm._l((item.Invoice.LineItems),function(item,index){return [_c('tr',{key:index},[_c('td',{staticStyle:{"height":"20px !important"}},[_vm._v(_vm._s(item.ItemCode))]),_c('td',{staticStyle:{"height":"20px !important"}},[_vm._v(_vm._s(item.Description))]),_c('td',{staticStyle:{"height":"20px !important"}},[_vm._v(_vm._s(item.Quantity))]),_c('td',{staticStyle:{"height":"20px !important"}},[_vm._v(_vm._s(item.UnitAmount))])])]})],2)]},proxy:true}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }