<template>
    <div>
        <v-data-table
            :headers="contactHeaders"
            :items="IntegratedContacts"
            :search="contactSearchTerm"
            calculate-widths
            class="pt-3 appic-table-light specification-table"
            dense
            item-key="Invoice.id"
        >
            <template v-slot:top>
                <div class="d-flex flex-row align-center">
                    <span class="font-weight-bold">{{ $t('message.buyers') }}</span>
                </div>
                <div class="d-flex flex-row mb-3">
                    <v-text-field
                        :value="contactSearchTerm"
                        append-icon="search"
                        class="filter-width-250"
                        dense
                        hide-details="auto"
                        solo
                        @change="contactSearchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" @click="resetContactSearch">{{ $t('message.clear') }}</v-btn>
                </div>
            </template>
            <template v-slot:item.Contact.Processed="{ item }">
                {{ item.Contact.Processed != '0000-00-00' ? formatDate(item.Contact.Processed) : '' }}
            </template>
            <template v-slot:item.Contact.Addresses="{ item }">
                <template v-for="(address, index) in item.Contact.Addresses">
                    <div class="d-flex flex-row" :key="index">
                        <span>{{ address.AddressType + ': ' +  (address.AddressLine1 != null ? address.AddressLine1.trim() : '') + (address.AddressLine2 != null ? address.AddressLine2.trim() : '') + (address.AddressLine3 != null ? address.AddressLine3.trim() : '') + (address.City != null ? address.City.trim() : '')  + (address.Region != null ? address.Region.trim() : '') + (address.PostalCode != null ? address.PostalCode.trim() : '')  + (address.Country != '' ? address.Country.trim() : '') }}</span>
                    </div>
                </template>
            </template>
            <template v-slot:item.Contact.ContactPersons="{ item }">
                <template v-for="(contactPerson, index) in item.Contact.ContactPersons">
                    <div class="d-flex flex-row" :key="index">
                        <span>{{ contactPerson.FirstName != null ? contactPerson.FirstName.trim() : '' }}</span>
                        <span class="ml-2">{{ '(' + (contactPerson.EmailAddress != null ? contactPerson.EmailAddress.trim() : '') + ')' }}</span>
                    </div>
                </template>
            </template>
            <template v-slot:item.Contact.Phones="{ item }">
                <template v-for="(phone, index) in item.Contact.Phones">
                    <div class="d-flex flex-row" :key="index">
                        <span>{{ phone.PhoneType + ': ' + phone.PhoneNumber  }}</span>
                    </div>
                </template>
            </template>
        </v-data-table>
        <v-data-table
            :headers="invoiceHeaders"
            :items="IntegratedInvoices"
            :search="invoiceSearchTerm"
            calculate-widths
            class="appic-table-light specification-table"
            dense
        >
            <template v-slot:top>
                <div class="d-flex flex-row align-center">
                    <span class="font-weight-bold">{{ $t('message.invoices') }}</span>
                </div>
                <div class="d-flex flex-row mb-3">
                    <v-text-field
                        :value="invoiceSearchTerm"
                        append-icon="search"
                        class="filter-width-250"
                        dense
                        hide-details="auto"
                        solo
                        @change="invoiceSearchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" @click="resetInvoiceSearch">{{ $t('message.clear') }}</v-btn>
                </div>
            </template>
            <template v-slot:item.Invoice.Processed="{ item }">
                {{ item.Invoice.Processed != '0000-00-00' ? formatDate(item.Invoice.Processed) : '' }}
            </template>
            <template v-slot:item.Invoice.Date="{ item }">
                {{ item.Invoice.Date != '0000-00-00' ? formatDate(item.Invoice.Date) : '' }}
            </template>
            <template v-slot:item.Invoice.DueDate="{ item }">
                {{ item.Invoice.DueDate != '0000-00-00' ? formatDate(item.Invoice.DueDate) : '' }}
            </template>
            <template v-slot:item.Invoice.LineItems="{ item }">
                <v-simple-table class="appic-table-light simple-table my-2" style="width: 400px">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="pl-1" style="background-color: unset !important; height: 20px !important;">ItemCode</th>
                            <th class="pl-1" style="height: 20px !important;">Description</th>
                            <th class="pl-1" style="height: 20px !important;">Quantity</th>
                            <th class="pl-1" style="height: 20px !important;">UnitAmount</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(item, index) in item.Invoice.LineItems">
                            <tr :key="index">
                                <td style="height: 20px !important;">{{ item.ItemCode }}</td>
                                <td style="height: 20px !important;">{{ item.Description }}</td>
                                <td style="height: 20px !important;">{{ item.Quantity }}</td>
                                <td style="height: 20px !important;">{{ item.UnitAmount }}</td>
                            </tr>
                        </template>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {mapMultiRowFields} from "vuex-map-fields";
import {formatDate} from "Helpers/helpers";
import {mapActions} from "vuex";

export default {
    name: "XeroIntegratedList",
    data(){
        return {
            contactSearchTerm: null,
            invoiceSearchTerm: null
        }
    },
    computed: {
        ...mapMultiRowFields('xero',{
            IntegratedContacts: 'IntegratedContacts',
            IntegratedInvoices: 'IntegratedInvoices'
        }),
        contactHeaders () {
            let headers = [
                {
                    id: 1,
                    text: 'Date Integrated',
                    value: 'Contact.Processed',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 2,
                    text: 'Organisation',
                    value: 'Contact.Organisation',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 3,
                    text: 'Name',
                    value: 'Contact.Name',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 4,
                    text: 'ContactNumber',
                    value: 'Contact.ContactNumber',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 5,
                    text: 'AccountNumber',
                    value: 'Contact.AccountNumber',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 6,
                    text: 'ContactStatus',
                    value: 'Contact.ContactStatus',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 7,
                    text: 'CompanyNumber',
                    value: 'Contact.CompanyNumber',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 8,
                    text: 'ContactPersons',
                    value: 'Contact.ContactPersons',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 9,
                    text: 'BankAccountDetails',
                    value: 'Contact.BankAccountDetails',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 10,
                    text: 'TaxNumber',
                    value: 'Contact.TaxNumber',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 11,
                    text: 'Addresses',
                    value: 'Contact.Addresses',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 12,
                    text: 'Phones',
                    value: 'Contact.Phones',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 13,
                    text: 'DefaultCurrency',
                    value: 'Contact.DefaultCurrency',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                }
            ]
            return headers
        },
        invoiceHeaders () {
            let headers = [
                {
                    id: 1,
                    text: 'Date Integrated',
                    value: 'Invoice.Processed',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false
                },
                {
                    id: 2,
                    text: 'Organisation',
                    value: 'Invoice.Organisation',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 3,
                    text: 'Type',
                    value: 'Invoice.AppicInvoiceType',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 4,
                    text: 'InvoiceNumber',
                    value: 'Invoice.InvoiceNumber',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 5,
                    text: 'Contact',
                    value: 'Invoice.Contact',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 6,
                    text: 'LineItems',
                    value: 'Invoice.LineItems',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 7,
                    text: 'Date',
                    value: 'Invoice.Date',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 8,
                    text: 'DueDate',
                    value: 'Invoice.DueDate',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 9,
                    text: 'LineAmountTypes',
                    value: 'Invoice.LineAmountTypes',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 10,
                    text: 'Reference',
                    value: 'Invoice.Reference',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 11,
                    text: 'CurrencyCode',
                    value: 'Invoice.CurrencyCode',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 12,
                    text: 'CurrencyRate',
                    value: 'Invoice.CurrencyRate',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 13,
                    text: 'Status',
                    value: 'Invoice.Status',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                }
            ]
            return headers
        },
        formatDate: () => formatDate
    },
    methods: {
        ...mapActions('xero',{
            getIntegratedContacts: 'getIntegratedContacts',
            getIntegratedInvoices: 'getIntegratedInvoices',
            resetIntegratedLists: 'resetIntegratedLists'
        }),
        resetContactSearch() {
            this.contactSearchTerm = null
        },
        resetInvoiceSearch() {
            this.invoiceSearchTerm = null
        }
    },
    mounted() {
        this.resetIntegratedLists()
        this.getIntegratedContacts()
            .then(() => {
                this.getIntegratedInvoices()
            })
    }
}
</script>

<style scoped>
.filter-width-250 {
    width: 250px !important;
    max-width: 250px !important;
}

.v-alert >>> .theme--light.v-icon {
    color: white !important;
}

.v-data-table >>>  th:first-child {
    background-color: #c5e1a5 !important;
    border-color: #c5e1a5 !important;
}

.v-data-table >>> td {
    padding: 0 3px !important;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.v-data-table >>> td:first-child {
    padding-left: 3px !important;
}
.v-data-table-header >>> th {
    white-space: nowrap;
}
</style>